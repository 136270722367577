var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('widget-card', {
    staticClass: "base-service-details",
    attrs: {
      "title": "Serwis",
      "withControls": ""
    },
    on: {
      "edit": _vm.openEditModal,
      "delete": function () {
        return _vm.$emit('delete', _vm.service);
      }
    },
    scopedSlots: _vm._u([_vm.service ? {
      key: "title",
      fn: function () {
        return [_vm._v(" " + _vm._s(_vm.serviceType) + " "), _c('div', {
          staticClass: "subtitle"
        }, [_vm._v(" z dnia " + _vm._s(_vm.formatDate.transport(_vm.service.date)) + " ")])];
      },
      proxy: true
    } : null], null, true)
  }, [_c('div', {
    staticClass: "status-dropdown-wrapper"
  }, [_vm.statusUpdateInProgress ? _c('vs-loader', {
    attrs: {
      "inline": "",
      "text": "Aktualizowanie statusu"
    }
  }) : _vm._e(), _c('vs-status-dropdown', {
    attrs: {
      "current-status-id": _vm.service.status.id,
      "statuses": _vm.statusStore.state
    },
    on: {
      "pick": _vm.updateServiceStatus
    }
  })], 1), _c('vs-button', {
    staticClass: "close-details",
    attrs: {
      "vs-variant": "light",
      "size": "sm"
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('close');
      }
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "XIcon"
    }
  })], 1), _vm.isLoading ? _c('vs-loader', {
    staticStyle: {
      "top": "0"
    },
    attrs: {
      "full": "",
      "text": "Pobieranie informacji o serwisie"
    }
  }) : _vm._e(), _vm.service ? [_c('details-table', {
    attrs: {
      "entries": _vm.serviceDetails,
      "i18nGroup": "base.service.details.field"
    },
    scopedSlots: _vm._u([{
      key: "value-resource",
      fn: function () {
        return [_c('base-resource-label', {
          attrs: {
            "resource": _vm.service.resource
          }
        })];
      },
      proxy: true
    }, {
      key: "value-reporter",
      fn: function () {
        return [_c('user-label', {
          attrs: {
            "data": _vm.service.reporter
          }
        })];
      },
      proxy: true
    }, {
      key: "value-accepting",
      fn: function () {
        return [_c('user-label', {
          attrs: {
            "data": _vm.service.accepting
          }
        })];
      },
      proxy: true
    }, {
      key: "value-meter_status",
      fn: function () {
        return [_vm._v(" " + _vm._s(_vm.service.meter_status) + " " + _vm._s(_vm.meterStatusUnit) + " ")];
      },
      proxy: true
    }, {
      key: "value-next_meter_status",
      fn: function () {
        return [_vm._v(" " + _vm._s(_vm.service.next_meter_status) + " " + _vm._s(_vm.meterStatusUnit) + " ")];
      },
      proxy: true
    }], null, false, 2928296002)
  }), _c('header-section', {
    staticClass: "mt-1",
    attrs: {
      "title": "Uwagi"
    }
  }, [_vm.service.description ? _c('div', {
    staticClass: "description"
  }, [_vm._v(" " + _vm._s(_vm.service.description) + " ")]) : _c('div', {
    staticClass: "no-description"
  }, [_vm._v(" Brak uwag ")])]), _c('hr'), _c('attachments', {
    attrs: {
      "useProps": "",
      "items": _vm.service.files
    },
    on: {
      "add": _vm.createAttachment,
      "remove": _vm.deleteAttachment
    }
  })] : _vm._e()], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }