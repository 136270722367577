var render = function render(){
  var _vm$currentStatus$nam, _vm$currentStatus;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-dropdown', {
    staticClass: "vs-status-dropdown",
    style: _vm.currentStyle,
    attrs: {
      "text": (_vm$currentStatus$nam = (_vm$currentStatus = _vm.currentStatus) === null || _vm$currentStatus === void 0 ? void 0 : _vm$currentStatus.name) !== null && _vm$currentStatus$nam !== void 0 ? _vm$currentStatus$nam : 'Wybierz status'
    }
  }, _vm._l(_vm.statuses, function (status) {
    return _c('b-dropdown-item', {
      key: status.id,
      style: _vm.getStatusStyling(status),
      on: {
        "click": function ($event) {
          return _vm.$emit('pick', status);
        }
      }
    }, [_vm._v(" " + _vm._s(status.name) + " ")]);
  }), 1);

}
var staticRenderFns = []

export { render, staticRenderFns }