<template>
  <b-dropdown
    class="vs-status-dropdown"
    :text="currentStatus?.name ?? 'Wybierz status'"
    :style="currentStyle"
    >
    <b-dropdown-item
      v-for="status in statuses"
      :key="status.id"
      :style="getStatusStyling(status)"
      @click="$emit('pick', status)"
      >
      {{ status.name }}
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
import getContrasting from '@/libs/contrast';
import { BDropdown, BDropdownItem } from 'bootstrap-vue';
import { computed } from 'vue';

export default {
  name: 'VsStatusDropdown',
  emits: ['pick'],
  props: {
    currentStatusId: Number,
    statuses: {
      type: Array,
      default: () => ([]),
    },
  },
  setup(props) {
    const currentStatus = computed(() => {
      if (!props.currentStatusId) return null;
      return props.statuses.find(({ id }) => id === props.currentStatusId) ?? null;
    });

    const currentStyle = computed(() => {
      if (!currentStatus.value) return null;
      return {
        '--current-status-color': currentStatus.value.color,
        '--current-status-fg': getContrasting(currentStatus.value.color),
      };
    });

    const getStatusStyling = ({ color }) => ({
      '--status-color': color,
      '--status-hover': getContrasting(color),
    });

    return {
      currentStatus,
      currentStyle,
      getStatusStyling,
    };
  },
  components: {
    BDropdown,
    BDropdownItem,
  },
};
</script>

<style lang="sass" scoped>
.vs-status-dropdown
  --current-status-color: var(--vs-card-bg-l-5)
  --current-status-fg: var(--vs-card-bg-l-40)

  &:deep
    button.dropdown-toggle
      background-color: var(--current-status-color) !important
      color: var(--current-status-fg) !important
      border-color: var(--current-status-color) !important

    a.dropdown-item
      color: var(--status-color)
      font-weight: 500

      &:hover
        background-color: var(--status-color) !important
        color: var(--status-hover) !important
</style>
