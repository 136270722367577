<template>
  <vs-modal
    :modalInstance="modalEditService"
    @ok="createResource"
  >
    <base-service-form
      v-model="fields"
      :feedback="feedback"
      :disableTypePicker="disableTypePicker"
      :resourceTypes="resourceTypes"
    />
  </vs-modal>
</template>

<script>
import VsModal from '@/components/ui/vs-modal/VsModal.vue';
import useBaseModals from '@/hooks/base/useBaseModals';
import useValidatedForm from '@/hooks/useValidatedForm';
import useBaseApi from '@/hooks/base/useBaseApi';
import showToast from '@/libs/toasts';
import { UpdateServiceError, UpdateServiceSuccess } from '@/libs/toasts/feedback/module/base';
import BaseServiceForm from '../forms/BaseServiceForm.vue';

export default {
  name: 'BaseServiceCreateModal',
  emits: ['ok'],
  props: {
    disableTypePicker: {
      type: Boolean,
      default: false,
    },
    resourceTypes: {
      type: Array,
    },
  },
  setup(_, { emit }) {
    const { modalEditService } = useBaseModals();

    const {
      fields,
      feedback,
      areAllFieldsValid,
      assignServerSideErrors,
      resetFields,
      assignFields,
    } = useValidatedForm({
      id: 'required',
      date: 'required',
      next_date: 'date_after:@date',
      meter_status: 'required',
      next_meter_status: 'min_value:@meter_status',
      description: '',
      type: 'required',
      base_resource_id: 'required',
      base_resource_service_status_id: 'required',
      reporting_user_id: 'required',
      accepting_user_id: 'required',
    });

    modalEditService.value.on({
      open(data) {
        resetFields();
        assignFields({ ...data });
      },
    });

    const createResource = async () => {
      if (!(await areAllFieldsValid())) return;

      modalEditService.value.isLoading = true;

      const sendData = {
        ...fields.value,
      };

      useBaseApi()
        .updateService(fields.value.id, {
          ...sendData,
        })
        .then(({ data }) => {
          showToast(UpdateServiceSuccess, { name: data.data?.name });
          modalEditService.value.close();
          modalEditService.value.notifyObservers('success');
          emit('ok', data.data);
        })
        .catch(({ response }) => {
          showToast(UpdateServiceError);
          assignServerSideErrors(response?.data?.errors);
        })
        .finally(() => {
          modalEditService.value.isLoading = false;
        });
    };

    return {
      modalEditService,
      fields,
      feedback,
      createResource,
    };
  },
  components: {
    VsModal,
    BaseServiceForm,
  },
};
</script>
