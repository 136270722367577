<template>
  <widget-card
    title="Serwis"
    class="base-service-details"
    withControls
    @edit="openEditModal"
    @delete="() => $emit('delete', service)"
  >
    <template #title v-if="service">
      {{ serviceType }}
      <div class="subtitle">
        z dnia {{ formatDate.transport(service.date) }}
      </div>
    </template>

    <div class="status-dropdown-wrapper">
      <vs-loader
        inline
        text="Aktualizowanie statusu"
        v-if="statusUpdateInProgress"
      />
      <vs-status-dropdown
        :current-status-id="service.status.id"
        :statuses="statusStore.state"
        @pick="updateServiceStatus"
      />
    </div>

    <vs-button
      vs-variant="light"
      size="sm"
      class="close-details"
      @click="$emit('close')"
    >
      <feather-icon icon="XIcon" />
    </vs-button>

    <vs-loader
      style="top: 0"
      full
      text="Pobieranie informacji o serwisie"
      v-if="isLoading"
    />

    <template v-if="service">
      <details-table
        :entries="serviceDetails"
        i18nGroup="base.service.details.field"
      >
        <template #value-resource>
          <base-resource-label
            :resource="service.resource"
          />
        </template>

        <template #value-reporter>
          <user-label
            :data="service.reporter"
          />
        </template>
        <template #value-accepting>
          <user-label
            :data="service.accepting"
          />
        </template>
        <template #value-meter_status>
          {{ service.meter_status }} {{ meterStatusUnit }}
        </template>
        <template #value-next_meter_status>
          {{ service.next_meter_status }} {{ meterStatusUnit }}
        </template>
      </details-table>

      <header-section class="mt-1" title="Uwagi">
        <div class="description" v-if="service.description">
          {{ service.description }}
        </div>
        <div class="no-description" v-else>
          Brak uwag
        </div>
      </header-section>

      <hr>

      <attachments
        useProps
        :items="service.files"
        @add="createAttachment"
        @remove="deleteAttachment"
      />
    </template>

  </widget-card>
</template>

<script>
import { computed, ref, watch } from 'vue';
import WidgetCard from '@/components/ui/WidgetCard.vue';
import useBaseApi from '@/hooks/base/useBaseApi';
import { useRouter } from '@/@core/utils/utils';
import i18n from '@/libs/i18n';
import formatDate from '@/libs/date-formatter';
import DetailsTable from '@/components/ui/DetailsTable.vue';
import Attachments from '@/components/ui/attachments/Attachments.vue';
import showToast from '@/libs/toasts';
import {
  AttachmentCreateError,
  AttachmentCreateSuccess,
  AttachmentDeleteError,
  AttachmentDeleteSuccess,
  AttachmentSizeError,
} from '@/libs/toasts/feedback/module/tasks';
import UserLabel from '@/components/ui/UserLabel.vue';
import HeaderSection from '@/components/ui/HeaderSection.vue';
import VsLoader from '@/components/ui/vs-loader/VsLoader.vue';
import useBaseModals from '@/hooks/base/useBaseModals';
import VsButton from '@/components/ui/vs-button/VsButton.vue';
import VsStatusDropdown from '@/components/ui/VsStatusDropdown.vue';
import useBaseServiceStatusStore from '@/stores/module/base/service/useBaseServiceStatusStore';
import { UpdateServiceError } from '@/libs/toasts/feedback/module/crm';
import baseCarsServiceTable from '@/hooks/tables/base/cars/service';
import BaseResourceLabel from '../resource/BaseResourceLabel.vue';

export default {
  name: 'BaseServiceDetails',
  emits: ['close'],
  setup() {
    const { route, router } = useRouter();
    const isLoading = ref(false);
    const serviceId = computed(() => route.value.params?.id);
    const service = ref(null);

    const serviceType = computed(() => {
      if (!service.value) return null;
      const typeSlug = service.value.type;

      return i18n.t(`base.service.type.${typeSlug}`);
    });

    const redirectToList = () => {
      router.push({ name: 'base.service' });
    };

    const fetchService = () => {
      if (!serviceId.value) return redirectToList();
      isLoading.value = true;

      useBaseApi()
        .fetchService(serviceId.value)
        .then(({ data }) => {
          service.value = data.data;
        })
        .catch(() => redirectToList())
        .finally(() => {
          isLoading.value = false;
        });

      return true;
    };

    fetchService();

    const serviceDetails = computed(() => {
      if (!service.value) return {};

      return {
        resource: service.value.resource,
        date: formatDate.transport(service.value.date),
        meter_status: service.value.meter_status,
        nextDate: formatDate.transport(service.value.next_date),
        next_meter_status: service.value.next_meter_status,
        reporter: service.value.reporter,
        accepting: service.value.accepting,
      };
    });

    const createAttachment = (attachment) => {
      const formData = new FormData();

      formData.append('file', attachment.file);
      formData.append('title', attachment.title);
      formData.append('mediable_id', serviceId.value);

      useBaseApi()
        .uploadServiceAttachment(formData)
        .then(({ data }) => {
          showToast(AttachmentCreateSuccess);
          service.value.files.push(data.data.file);
        })
        .catch(({ response }) => {
          if (response.status === 413 || response.status === 422) {
            showToast(AttachmentSizeError);
          } else {
            showToast(AttachmentCreateError);
          }
        });
    };

    const deleteAttachment = (attachmentId) => {
      useBaseApi()
        .deleteAttachment(attachmentId)
        .then(() => {
          showToast(AttachmentDeleteSuccess);
          service.value.files = service.value.files
            .filter((existing) => existing.id !== attachmentId);
        })
        .catch(() => {
          showToast(AttachmentDeleteError);
        });
    };

    const meterStatusUnit = computed(() => {
      if (!service.value) return 'mth';
      return service.value.resource?.type?.slug === 'car'
        ? 'km'
        : 'mth';
    });

    watch(() => serviceId.value, () => {
      fetchService();
    });

    const { modalEditService } = useBaseModals();
    const openEditModal = () => {
      modalEditService.value.open({ ...service.value });
    };

    modalEditService.value.on({ success: () => fetchService() });

    const statusStore = useBaseServiceStatusStore();
    const statusUpdateInProgress = ref(false);
    const updateServiceStatus = (status) => {
      if (!status || status.id === service.value?.status?.id) return;
      statusUpdateInProgress.value = true;

      useBaseApi()
        .updateService(service.value.id, {
          ...service.value,
          base_resource_service_status_id: status.id,
        })
        .then(() => {
          service.value.status = status;
          baseCarsServiceTable.value.fetchData();
        })
        .catch(() => {
          showToast(UpdateServiceError);
        })
        .finally(() => {
          statusUpdateInProgress.value = false;
        });
    };

    return {
      service,
      fetchService,
      serviceType,
      formatDate,
      serviceDetails,
      createAttachment,
      deleteAttachment,
      meterStatusUnit,
      isLoading,
      openEditModal,
      statusStore,
      updateServiceStatus,
      statusUpdateInProgress,
    };
  },
  components: {
    WidgetCard,
    DetailsTable,
    BaseResourceLabel,
    Attachments,
    UserLabel,
    HeaderSection,
    VsLoader,
    VsButton,
    VsStatusDropdown,
  },
};
</script>

<style lang="scss" scoped>
.base-service-details {
  position: relative;
  overflow: visible;

  .status-dropdown-wrapper {
    position: relative;
    margin-bottom: 20px;
  }

  .subtitle {
    padding-top: 5px;
    font-size: 12pt;
    font-weight: 400;
  }

  .vs-button.close-details {
    aspect-ratio: 1/1;
    padding: 5px;
    position: absolute;
    top: -5px;
    right: -5px;
  }

  .no-description {
    font-style: italic;
  }
}
</style>
